export default (value: string | number | undefined) => {
  if (!value || typeof value === 'number') {
    return ''
  }
  const words = value.split(' ').filter(o => o)
  if (words.length === 1) {
    return words[0][0].toUpperCase()
  } else if (words.length === 2) {
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`
  } else {
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}${words[words.length - 1][0].toUpperCase()}`
  }
}
