export const userIds = [
  'cGTZR3bQLCsuh1JP7KXU', // victor prod
  'vtcy42OGlsyYyHejg8it', // victor stag
  'en0W4Q5fTX7HDNIeIKWx', // jacob prod
  'mn60v3A8MYmz01yJOeSg', // jacob stag
  'AdVIJPcQTappZrtCubHa', // tim prod
  'Itf8mjd4THQ2jyxJqQHz', // tim stag
  '2d9quoX8zYDJjXFukh36', // david prod
  'GhBxuny5i9hQMBoo8vmw', // david stag
  'elRqyjKuB3x36RNg3KZQ', // gyunay prod
  'rScCFca0ZVRDxoaxhGeB' // gyunay stag
]
