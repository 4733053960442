
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'

@Component({
  name: 'UserNameEdit'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      if (model.name === this.userModule.user?.name) {
        this.$emit('success')
        return
      }

      const user = await this.userModule.userUpdate({
        name: model.name
      })

      analytics.userUpdate.track(this, {
        userEmail: user.email,
        userJobRole: user.name,
        userNotifications: user.notifications,
        userReferredBy: user.referredBy,
        userReferredByOrganizationId: user.referredByOrganizationId,
        userReferredByUserId: user.referredByUserId,
        userUpdateName: model.name
      })
    }
    this.formController.on('success', () => {
      this.$emit('success')
    })
    this.formController.on('error', error => {
      this.$emit('cancel')

      this.alertModule.pushAlert({
        color: 'error',
        message: error
      })
    })

    this.formController.setInitialModel({
      name: this.userModule.user?.name || ''
    })
    this.formController.resetModel()
  }
}
