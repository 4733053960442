
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import getColor from '@/helpers/get-color'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import getInitials from '../../helpers/get-initials'
import { UserModule } from '../../store'
import UserMenu from '../menu.vue'

interface IAvatar {
  color: string
  id: string
  initials: string
}

@Component({
  components: {
    UserMenu
  },
  name: 'UserAppbarAvatars'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentlyActiveLocations () {
    const timestamp = Math.round(Date.now() / 1000)
    return Object.values(this.editorModule.locations).filter(o => o.userId !== this.userModule.user?.id && o.versionId && o.publishedAt >= timestamp - 60)
  }

  get avatars () {
    const avatars: IAvatar[] = []

    if (this.userModule.user) {
      const color = getColor(this.userModule.user.id)
      avatars.push({
        color: TAG_COLOR_ACTIVE[color],
        id: this.userModule.user.id,
        initials: getInitials(this.userModule.user.name || '')
      })
    }

    this.currentlyActiveLocations
      .filter(o => o.userId !== this.userModule.user?.id)
      .forEach(o => {
        const user = this.currentLandscape ? this.organizationModule.organizationUsers[this.currentLandscape.organizationId]?.[o.userId] : undefined
        if (user) {
          const color = getColor(o.userId)
          avatars.push({
            color: TAG_COLOR_ACTIVE[color],
            id: o.userId,
            initials: user?.name ? getInitials(user.name) : ''
          })
        }
      })

    return avatars
  }

  get avatarCount () {
    return this.avatars.length
  }

  get avatarSliced () {
    return this.avatars.slice(0, 5)
  }
}
