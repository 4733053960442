import { OrganizationPlan, PermissionType } from '@icepanel/platform-api-client'
import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { OrganizationModule } from '@/modules/organization/store'
import * as firebase from '@/plugins/firebase'

import { UserModule } from '../store'

export default (store: Store<any>, router: VueRouter) => {
  const initialLoad = Promise.all([
    new Promise<void>(resolve => {
      store.subscribe(mutation => {
        if (mutation.type === 'user/setFirebaseUser') {
          resolve()
        }
      })
    }),
    new Promise<void>(resolve => {
      store.subscribe(mutation => {
        if (mutation.type === 'user/setUser') {
          resolve()
        }
      })
    }),
    firebase.initialLoad
  ])

  router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const userModule = getModule(UserModule, store)

    await initialLoad

    if (requiresAuth && (!userModule.user || !userModule.firebaseUser)) {
      if (env.IS_SHARE_LINK) {
        next({
          name: 'share-link-not-found'
        })
      } else if (to.query.plan || to.query.trial) {
        next({
          name: 'user-register',
          query: to.query
        })
      } else {
        next({
          name: 'user-login',
          query: to.query
        })
      }
    } else if (to.path === '/' && userModule.user && userModule.firebaseUser) {
      if (env.IS_SHARE_LINK) {
        next({
          name: 'share-link-not-found'
        })
      } else {
        next({
          name: 'organizations',
          query: to.query
        })
      }
    } else if (to.path === '/') {
      if (env.IS_SHARE_LINK) {
        next({
          name: 'share-link-not-found'
        })
      } else if (to.query.plan || to.query.trial) {
        next({
          name: 'user-register',
          query: to.query
        })
      } else {
        next({
          name: 'user-login',
          query: to.query
        })
      }
    } else {
      next()
    }
  })

  router.beforeEach(async (to, from, next) => {
    const organizationModule = getModule(OrganizationModule, store)
    const userModule = getModule(UserModule, store)

    const organizationId = to.params.organizationId
    if (!organizationId) {
      return next()
    }

    if (!organizationModule.organizations.some(o => o.id === organizationId)) {
      try {
        await organizationModule.organizationFind(organizationId)
      } catch (err) {}
    }

    const organization = organizationModule.organizations.find(o => o.id === organizationId)
    if (!organization) {
      return next({
        name: 'organizations',
        query: to.query
      })
    }

    const requiresPermission = to.matched.map(record => record.meta.requiresPermission as PermissionType | undefined)[0]
    const organizationPermission = organizationModule.organizationPermission(organization)
    if (requiresPermission && !userModule.isAdmin && (
      (requiresPermission === 'admin' && organizationPermission !== 'admin') ||
      (requiresPermission === 'billing' && organizationPermission !== 'billing' && organizationPermission !== 'admin') ||
      (requiresPermission === 'write' && organizationPermission !== 'admin' && organizationPermission !== 'write') ||
      (requiresPermission === 'read' && organizationPermission !== 'admin' && organizationPermission !== 'write' && organizationPermission !== 'read')
    )) {
      return next({
        name: 'landscapes',
        params: {
          organizationId
        },
        query: to.query
      })
    }

    const requiresPlans = to.matched.map(record => record.meta.requiresPlan as OrganizationPlan[] | undefined)
    const requiresPlan = requiresPlans[requiresPlans.length - 1]
    if (requiresPlan && !requiresPlan.includes(organization.plan)) {
      return next({
        name: 'landscapes',
        params: {
          organizationId
        },
        query: to.query
      })
    }

    next()
  })
}
