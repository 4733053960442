import * as analytics from '@icepanel/app-analytics'
import { UserGoalName, UserNotifications } from '@icepanel/platform-api-client'

export const userId: analytics.Property<string[]> = {
  displayName: 'User ID',
  name: 'user_id',
  required: true,
  type: 'array'
}
export const userCount: analytics.Property<number> = {
  displayName: 'User count',
  name: 'user_count',
  required: true,
  type: 'number'
}

const organizationId: analytics.Property<string[]> = {
  displayName: 'Organization ID',
  name: 'organization_id',
  required: true,
  type: 'array'
}
const landscapeId: analytics.Property<string[]> = {
  displayName: 'Landscape ID',
  name: 'landscape_id',
  required: true,
  type: 'array'
}
const teamId: analytics.Property<string[]> = {
  displayName: 'Team ID',
  name: 'team_id',
  required: true,
  type: 'array'
}

const organizationCount: analytics.Property<number> = {
  displayName: 'Organization count',
  name: 'organization_count',
  required: true,
  type: 'number'
}
const landscapeCount: analytics.Property<number> = {
  displayName: 'Landscape count',
  name: 'landscape_count',
  required: true,
  type: 'number'
}
const teamCount: analytics.Property<number> = {
  displayName: 'Team count',
  name: 'team_count',
  required: true,
  type: 'number'
}
const notifications: analytics.Property<UserNotifications> = {
  displayName: 'Notifications',
  name: 'notifications',
  required: true,
  type: 'object'
}

const createdAt: analytics.Property<Date> = {
  displayName: 'Created at',
  name: '$created',
  required: true,
  type: 'date'
}
const email: analytics.Property<string> = {
  displayName: 'Email',
  name: '$email',
  required: true,
  type: 'string'
}
const emailVerified: analytics.Property<boolean> = {
  displayName: 'Email verified',
  name: 'email_verified',
  required: true,
  type: 'boolean'
}
const emailVerifiedAt: analytics.Property<Date> = {
  displayName: 'Email verified at',
  name: 'email_verified_at',
  required: true,
  type: 'date'
}
const goalsCompleted: analytics.Property<UserGoalName[]> = {
  displayName: 'Goals completed',
  examples: [['create-comment', 'open-context-diagram', 'zoom-into-object']],
  name: 'goals_completed',
  required: true,
  type: 'array'
}
const goalsCompletedCount: analytics.Property<number> = {
  displayName: 'Goals completed count',
  name: 'goals_completed_count',
  required: true,
  type: 'number'
}
const name: analytics.Property<string | undefined> = {
  displayName: 'Name',
  name: '$name',
  required: false,
  type: 'string'
}
const jobRole: analytics.Property<string | undefined> = {
  displayName: 'Job role',
  name: 'job_role',
  required: false,
  type: 'string'
}
const lastActiveAt: analytics.Property<Date> = {
  displayName: 'Last active at',
  name: 'last_active_at',
  required: true,
  type: 'date'
}
const referredBy: analytics.Property<string | undefined> = {
  displayName: 'Referred by',
  name: 'referred_by',
  required: false,
  type: 'string'
}
const referredByUserId: analytics.Property<string | undefined> = {
  displayName: 'Referred by user ID',
  name: 'referred_by_user_id',
  required: false,
  type: 'string'
}
const referredByOrganizationId: analytics.Property<string | undefined> = {
  displayName: 'Referred by organization ID',
  name: 'referred_by_organization_id',
  required: false,
  type: 'string'
}
const c4ModelExperience: analytics.Property<string | undefined> = {
  displayName: 'C4 model experience',
  name: 'c4_model_experience',
  required: false,
  type: 'string'
}

export default new analytics.Profile({
  c4ModelExperience,
  createdAt,
  email,
  emailVerified,
  emailVerifiedAt,
  goalsCompleted,
  goalsCompletedCount,
  jobRole,
  landscapeCount,
  landscapeId,
  lastActiveAt,
  name,
  notifications,
  organizationCount,
  organizationId,
  referredBy,
  referredByOrganizationId,
  referredByUserId,
  teamCount,
  teamId
})

/**
 * Hidden
 */
export const companySizeHidden: analytics.Property<string> = {
  displayName: 'Company size',
  examples: ['1-9', '10-49', '50-249', '250+'],
  hidden: true,
  name: 'company_size',
  required: true,
  type: 'string'
}
export const sendNewsHidden: analytics.Property<boolean> = {
  displayName: 'Send news',
  hidden: true,
  name: 'send_news',
  required: true,
  type: 'boolean'
}
