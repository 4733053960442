import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'
import { organizationPlan } from '@/modules/organization/helpers/analytics-properties'

import {
  userAuthProvider,
  userC4ModelExperience,
  userEmail,
  userGoalName,
  userGoalProgressCount,
  userGoalProgressData,
  userGoalStepCount,
  userJobRole,
  userNotifications,
  userReferredBy,
  userReferredByOrganizationId,
  userReferredByUserId,
  userUpdateC4ModelExperience,
  userUpdateEmail,
  userUpdateJobRole,
  userUpdateName,
  userUpdateNotifications,
  userUpdateReferredBy,
  userUpdateReferredByOrganizationId,
  userUpdateReferredByUserId
} from './analytics-properties'

/**
 * Screens
 */

export const userLoginScreen = new analytics.Event({
  displayName: 'User login screen',
  name: 'user_login_screen',
  tags: [analytics.Tags.Screen]
}, {
  userAuthProvider
})
export const userVerifyEmailScreen = new analytics.Event({
  displayName: 'User verify email screen',
  name: 'user_verify_email_screen',
  tags: [analytics.Tags.Screen]
})
export const userRegisterScreen = new analytics.Event({
  displayName: 'User register screen',
  name: 'user_register_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationPlan: {
    ...organizationPlan,
    required: false
  },
  userAuthProvider
})
export const userRequestResetPasswordScreen = new analytics.Event({
  displayName: 'User request reset password screen',
  name: 'user_request_reset_password_screen',
  tags: [analytics.Tags.Screen]
})
export const userResetPasswordScreen = new analytics.Event({
  displayName: 'User reset password screen',
  name: 'user_reset_password_screen',
  tags: [analytics.Tags.Screen]
})
export const userUnsubscribeScreen = new analytics.Event({
  displayName: 'User unsubscribe screen',
  name: 'user_unsubscribe_screen',
  tags: [analytics.Tags.Screen]
})
export const userProfileSetupScreen = new analytics.Event({
  displayName: 'User profile setup screen',
  name: 'user_profile_setup_screen',
  tags: [analytics.Tags.Screen]
})

/**
 * Menus
 */

export const userMenu = new analytics.Event({
  displayName: 'User menu',
  name: 'user_menu',
  tags: [analytics.Tags.Menu]
})
export const userProfileMenu = new analytics.Event({
  displayName: 'User profile menu',
  name: 'user_profile_menu',
  tags: [analytics.Tags.Menu]
})

/**
 * Events
 */

export const userLoginForm = new analytics.Event({
  displayName: 'User login form',
  name: 'user_login_form'
}, {
  userAuthProvider
})
export const userLogin = new analytics.Event({
  displayName: 'User login',
  name: 'user_login'
}, {
  userAuthProvider
})
export const userLogout = new analytics.Event({
  displayName: 'User logout',
  name: 'user_logout'
})
export const userUpdateForm = new analytics.Event({
  displayName: 'User update form',
  name: 'user_update_form'
})
export const userUpdate = new analytics.Event({
  displayName: 'User update',
  name: 'user_update'
}, {
  userC4ModelExperience,
  userEmail,
  userJobRole,
  userNotifications,
  userReferredBy,
  userReferredByOrganizationId,
  userReferredByUserId,
  userUpdateC4ModelExperience,
  userUpdateEmail,
  userUpdateJobRole,
  userUpdateName,
  userUpdateNotifications,
  userUpdateReferredBy,
  userUpdateReferredByOrganizationId,
  userUpdateReferredByUserId
})
export const userRequestResetPasswordForm = new analytics.Event({
  displayName: 'User request reset password form',
  name: 'user_request_reset_password_form'
})
export const userRequestResetPassword = new analytics.Event({
  displayName: 'User request reset password',
  name: 'user_request_reset_password'
})
export const userResetPasswordForm = new analytics.Event({
  displayName: 'User reset password form',
  name: 'user_reset_password_form'
})
export const userResetPassword = new analytics.Event({
  displayName: 'User reset password',
  name: 'user_reset_password'
})
export const userRegisterForm = new analytics.Event({
  displayName: 'User register form',
  name: 'user_register_form'
}, {
  userAuthProvider
})
export const userRegister = new analytics.Event({
  displayName: 'User register',
  name: 'user_register'
}, {
  userAuthProvider,
  userReferredBy,
  userReferredByOrganizationId,
  userReferredByUserId
})
export const userVerifyEmail = new analytics.Event({
  displayName: 'User verify email',
  name: 'user_verify_email'
})
export const userUnsubscribe = new analytics.Event({
  displayName: 'User unsubscribe',
  name: 'user_unsubscribe'
})
export const userGoalProgress = new analytics.Event({
  displayName: 'User goal progress',
  name: 'user_goal_progress'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  },
  userGoalName,
  userGoalProgressCount,
  userGoalProgressData,
  userGoalStepCount
})
export const userGoalComplete = new analytics.Event({
  displayName: 'User goal complete',
  name: 'user_goal_complete'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  },
  userGoalName,
  userGoalProgressCount,
  userGoalProgressData,
  userGoalStepCount
})
export const userGoalVideoOpen = new analytics.Event({
  displayName: 'User goal video open',
  name: 'user_goal_video_open'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  },
  userGoalName
})

/**
 * Hidden
 */

export const userProfileDialogHidden = new analytics.Event({
  displayName: 'User profile dialog',
  hidden: true,
  name: 'user_profile_dialog',
  tags: [analytics.Tags.Dialog]
})
