
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import LicensesDialog from '@/components/licenses-dialog.vue'
import Status from '@/helpers/status'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'

import * as analytics from '../helpers/analytics'
import { UserModule } from '../store'
import EmailEdit from './email-edit.vue'
import JobRoleEdit from './job-role-edit.vue'
import NameEdit from './name-edit.vue'

@Component({
  components: {
    EmailEdit,
    JobRoleEdit,
    LicensesDialog,
    NameEdit
  },
  name: 'UserProfileMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Prop() readonly menuOpts?: any

  isChangingPassword = false
  isLoggingOut = false

  editingName = false
  editingJobRole = false
  editingEmail = false

  get user () {
    return this.userModule.user
  }

  get licensesDialog () {
    return this.$queryValue('licenses_dialog')
  }

  open () {
    analytics.userProfileMenu.track(this)

    this.$emit('open')
  }

  close () {
    this.editingName = false
    this.editingJobRole = false
    this.editingEmail = false

    this.$emit('close')
  }

  async changePassword () {
    if (this.isChangingPassword) {
      return
    }
    this.isChangingPassword = true

    if (!this.userModule.user) {
      throw new Error('The user is not logged in')
    }

    try {
      await this.userModule.userRequestResetPassword({
        email: this.userModule.user.email
      })
      analytics.userRequestResetPasswordScreen.track(this)

      this.alertModule.pushAlert({
        color: 'success',
        message: 'We\'ve sent you an email with a link to reset your password'
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body?.message || err.message
      })
    } finally {
      this.isChangingPassword = false
    }
  }

  async logout () {
    if (this.isLoggingOut) {
      return
    }
    this.isLoggingOut = true

    try {
      await this.userModule.userLogout()
      analytics.userLogout.track(this)

      this.landscapeModule.setLandscapes([])
      this.landscapeModule.setLandscapesListStatus(Status.idle())
      this.organizationModule.resetOrganizations()
      this.organizationModule.setOrganizationsListStatus(Status.idle())

      this.$router.push({
        name: 'user-login'
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.isLoggingOut = false
    }
  }
}
