import { IconDefinition, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueConstructor from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify/lib'
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'

import attachAuthListener from './helpers/auth-listener'
import attachAuthRouteHandler from './helpers/auth-route-handler'
import icons from './helpers/icons'
import routes from './routes'
import { UserModule } from './store'

export const install = ({ vuetify, store, library, router }: { vuetify: Vuetify, store: Store<any>, library: Library, router: VueRouter, Vue: typeof VueConstructor }) => {
  icons.forEach(definition => {
    library.add(definition as IconDefinition)

    vuetify.framework.icons.values[`${definition.prefix}-${definition.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [definition.prefix, definition.iconName]
      }
    }
  })

  attachAuthListener(store)
  attachAuthRouteHandler(store, router)

  store.registerModule(UserModule.namespace, UserModule)
  const userModule = getModule(UserModule, store)

  if (env.IS_SHARE_LINK) {
    try {
      const userGoalsComplete = JSON.parse(localStorage.getItem('shareLinkUserGoalsComplete') || '[]')
      userModule.setUserGoals([{
        createdAt: '',
        id: '',
        name: 'zoom-into-object',
        progress: 0,
        progressData: [],
        status: userGoalsComplete.includes('zoom-into-object') ? 'complete' : 'incomplete',
        steps: 0,
        updatedAt: '',
        userId: ''
      }])
    } catch (err) {
      console.error(err)
    }
  } else {
    router.beforeEach(async (to, from, next) => {
      // Redirect to app if user is logged in, and tries to access a route that requires unauth
      if (to.matched.some(r => r.meta.requiresUnauth && r.meta.redirectUnauth) && userModule.user) {
        next('/')
      } else {
        next()
      }
    })

    router.addRoutes(routes)
  }
}
