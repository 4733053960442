import { RouteConfig } from 'vue-router'

export default [
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "user-register" */ './views/register.vue'),
        meta: {
          redirectUnauth: false,
          requiresUnauth: true,
          title: 'Sign up'
        },
        name: 'user-register',
        path: 'sign-up'
      },
      {
        component: () => import(/* webpackChunkName: "user-register-saml" */ './views/register-saml.vue'),
        meta: {
          redirectUnauth: false,
          requiresUnauth: true,
          title: 'Sign up'
        },
        name: 'user-register-saml',
        path: 'sign-up/saml'
      },
      {
        component: () => import(/* webpackChunkName: "user-login" */ './views/login.vue'),
        meta: {
          redirectUnauth: true,
          requiresUnauth: true,
          title: 'Log in'
        },
        name: 'user-login',
        path: 'login'
      },
      {
        component: () => import(/* webpackChunkName: "user-login-saml" */ './views/login-saml.vue'),
        meta: {
          redirectUnauth: true,
          requiresUnauth: true,
          title: 'Log in'
        },
        name: 'user-login-saml',
        path: 'login/saml'
      },
      {
        component: () => import(/* webpackChunkName: "user-verify-email" */ './views/verify-email.vue'),
        meta: {
          redirectUnauth: false,
          requiresUnauth: true,
          title: 'Verify email'
        },
        name: 'user-verify-email',
        path: 'verify-email'
      },
      {
        component: () => import(/* webpackChunkName: "user-unsubscribe" */ './views/unsubscribe.vue'),
        meta: {
          redirectUnauth: false,
          requiresUnauth: true,
          title: 'Unsubscribe'
        },
        name: 'user-unsubscribe',
        path: 'unsubscribe'
      },
      {
        component: () => import(/* webpackChunkName: "user-request-reset-password" */ './views/request-reset-password.vue'),
        meta: {
          redirectUnauth: false,
          requiresUnauth: true,
          title: 'Forgotten password'
        },
        name: 'user-request-reset-password',
        path: 'request-password-reset'
      },
      {
        component: () => import(/* webpackChunkName: "user-reset-password" */ './views/reset-password.vue'),
        meta: {
          redirectUnauth: false,
          requiresUnauth: true,
          title: 'Reset password'
        },
        name: 'user-reset-password',
        path: 'reset-password'
      }
    ],
    component: () => import(/* webpackChunkName: "user" */ './views/index.vue'),
    meta: {
      background: 'lights',
      requiresUnauth: true
    },
    path: '/user',
    redirect: {
      name: 'user-login'
    }
  },
  {
    component: () => import(/* webpackChunkName: "user-profile-setup" */ './views/profile-setup.vue'),
    meta: {
      background: '#323435',
      hasAppbar: false,
      hasDrawerLeft: false,
      requiresAuth: true,
      requiresSocket: false,
      title: 'Profile setup'
    },
    name: 'user-profile-setup',
    path: '/user/profile-setup'
  }
] as RouteConfig[]
