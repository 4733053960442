import { NewsService, PermissionType, User, UserGoal, UserGoalsService, UserNotificationsPartial, UserNotificationsService, UserPartial, UserService } from '@icepanel/platform-api-client'
import * as Sentry from '@sentry/browser'
import { FirebaseApp } from 'firebase/app'
import {
  AuthProvider as FirebaseAuthProvider,
  getAuth,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  unlink,
  User as FirebaseUser
} from 'firebase/auth'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import * as firebase from '@/plugins/firebase'
import * as mixpanel from '@/plugins/mixpanel'

import * as admin from './helpers/admin'
import userAnalyticsProfile from './helpers/analytics-profile'

export interface IUserModule {
  loaded: boolean
  firebaseUser: FirebaseUser | null

  user: User | null
  userGoals: UserGoal[]
}

const name = 'user'

@Module({
  name,
  namespaced: true
})
export class UserModule extends VuexModule {
  static namespace = name

  loaded = false
  firebaseUser: FirebaseUser | null = null

  user: User | null = null
  userGoals: UserGoal[] = []

  get isAdmin () {
    return this.user ? admin.userIds.includes(this.user.id) : false
  }

  get currentUserGoals () {
    return (permission: PermissionType | null) => {
      if (env.IS_SHARE_LINK) {
        return [
          this.userGoals.find(o => o.name === 'open-context-diagram'),
          this.userGoals.find(o => o.name === 'zoom-into-object')
        ].filter((o): o is UserGoal => !!o)
      } else if (permission === 'read') {
        return [
          this.userGoals.find(o => o.name === 'open-context-diagram'),
          this.userGoals.find(o => o.name === 'create-comment'),
          this.userGoals.find(o => o.name === 'zoom-into-object')
        ].filter((o): o is UserGoal => !!o)
      } else {
        return [
          this.userGoals.find(o => o.name === 'flows'),
          this.userGoals.find(o => o.name === 'zoom-into-object'),
          this.userGoals.find(o => o.name === 'model-objects')
        ].filter((o): o is UserGoal => !!o)
      }
    }
  }

  @Mutation
  setLoaded (loaded: boolean) {
    this.loaded = loaded
  }

  @Mutation
  setFirebaseUser (firebaseUser: FirebaseUser | null) {
    this.firebaseUser = firebaseUser
  }

  @Mutation
  setUser (user: User | null) {
    if (user && user.id !== this.user?.id) {
      mixpanel.identify(user.id)
    }
    if (!user && this.user) {
      Sentry.setUser(null)
      mixpanel.reset()
    }

    this.user = user

    if (user) {
      userAnalyticsProfile.set({
        createdAt: user.createdAt,
        email: user.email,
        jobRole: user.jobRole,
        lastActiveAt: user.lastActiveAt,
        name: user.name,
        referredBy: user.referredBy,
        referredByOrganizationId: user.referredByOrganizationId,
        referredByUserId: user.referredByUserId
      })

      Sentry.setUser({
        email: user.email,
        id: user.id
      })
    }
  }

  @Mutation
  setUserGoals (userGoals: UserGoal[]) {
    this.userGoals = userGoals
  }

  @Mutation
  upsertUserGoal (userGoal: UserGoal) {
    const index = this.userGoals.findIndex(o => o.id === userGoal.id)
    Vue.set(this.userGoals, index, userGoal)
  }

  @Action({ rawError: true })
  async getIdToken () {
    if (this.firebaseUser) {
      return this.firebaseUser.getIdToken()
    } else {
      throw new Error('User token not found')
    }
  }

  @Action({ rawError: true })
  async getAuthorizationHeader () {
    if (this.firebaseUser) {
      return `Bearer ${await this.firebaseUser.getIdToken()}`
    } else {
      throw new Error('User token not found')
    }
  }

  @Action({ rawError: true })
  async userRegister (body: Parameters<typeof UserService.userRegister>[0]) {
    try {
      await UserService.userRegister(body)
    } catch (err: any) {
      if (err.status === 429) {
        throw new Error('Too many requests, please try again later')
      } else {
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async firebaseLogin (body: { token: string, app: FirebaseApp } | { redirectProvider: FirebaseAuthProvider, app: FirebaseApp } | { popupProvider: FirebaseAuthProvider, app: FirebaseApp }) {
    if ('redirectProvider' in body) {
      await signInWithRedirect(getAuth(body.app), body.redirectProvider)
    } else if ('popupProvider' in body) {
      const signInRes = await signInWithPopup(getAuth(body.app), body.popupProvider)
      return signInRes.user
    } else {
      const signInRes = await signInWithCustomToken(getAuth(body.app), body.token)
      return signInRes.user
    }
  }

  @Action({ rawError: true })
  async firebaseUnlink (body: { user: FirebaseUser, provider: FirebaseAuthProvider }) {
    await unlink(body.user, body.provider.providerId)
  }

  @Action({ rawError: true })
  async userAuthPassword (body: { email: string, password: string }) {
    try {
      const { authToken } = await UserService.userAuthPassword(body)
      return {
        authToken
      }
    } catch (err: any) {
      if (err.status === 429) {
        throw new Error('Too many requests, please try again later')
      } else {
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async userLogin (body: { user: FirebaseUser, app: FirebaseApp } | { token: string, app: FirebaseApp } | { redirectProvider: FirebaseAuthProvider, app: FirebaseApp } | { popupProvider: FirebaseAuthProvider, app: FirebaseApp }) {
    const firebaseUser: FirebaseUser = 'user' in body ? body.user : window.Cypress ? window.firebaseLogin(body) : await this.context.dispatch('firebaseLogin', body)

    const token = await firebaseUser.getIdToken()
    const { user } = await UserService.userLogin(`Bearer ${token}`)

    this.setFirebaseUser(firebaseUser)
    this.setUser(user)

    if (env.IS_SHARE_LINK) {
      await this.context.dispatch('organization/organizationsList', undefined, { root: true })
    } else {
      await Promise.all([
        this.context.dispatch('userGoalsList'),
        this.context.dispatch('organization/organizationsList', undefined, { root: true })
      ])
    }

    return user
  }

  @Action({ rawError: true })
  async userFind () {
    if (this.firebaseUser) {
      const authorization = await this.context.dispatch('getAuthorizationHeader')
      const { user } = await UserService.userFind(authorization)

      this.setUser(user)

      if (env.IS_SHARE_LINK) {
        await this.context.dispatch('organization/organizationsList', undefined, { root: true })
      } else {
        await Promise.all([
          this.context.dispatch('userGoalsList'),
          this.context.dispatch('organization/organizationsList', undefined, { root: true })
        ])
      }
    } else {
      throw new Error('Firebase user missing')
    }
  }

  @Action({ rawError: true })
  async userUpdate (body: UserPartial) {
    if (this.firebaseUser) {
      const authorization = await this.context.dispatch('getAuthorizationHeader')
      const { user } = await UserService.userUpdate(authorization, body)

      this.setUser(user)

      return user
    } else {
      throw new Error('Firebase user missing')
    }
  }

  @Action({ rawError: true })
  async userLogout () {
    if (this.firebaseUser) {
      const authorization = await this.context.dispatch('getAuthorizationHeader')
      await UserService.userLogout(authorization)
      await signOut(getAuth(firebase.app))

      this.setFirebaseUser(null)
      this.setUser(null)
    }
  }

  @Action({ rawError: true })
  async userVerifyEmail ({ userId, token }: { userId: string, token: string }) {
    mixpanel.identify(userId)

    Sentry.setUser({
      id: userId
    })

    return UserService.userVerifyEmail(userId, token)
  }

  @Action({ rawError: true })
  async userRequestResetPassword (body: { email: string }) {
    try {
      return UserService.userRequestResetPassword(body)
    } catch (err: any) {
      if (err.status === 429) {
        throw new Error('Too many requests, please try again later')
      } else {
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async userResetPassword (body: { userId: string, token: string, password: string }) {
    const { authToken } = await UserService.userResetPassword(body.userId, body.token, {
      password: body.password
    })
    return { authToken }
  }

  @Action({ rawError: true })
  async userEmailPrepare (body: { email: string }) {
    try {
      await UserService.userEmailPrepare(body)
    } catch (err: any) {
      if (err.status === 429) {
        throw new Error('Too many requests, please try again later')
      } else {
        throw err
      }
    }
  }

  @Action({ rawError: true })
  async userGoalsList () {
    if (!this.firebaseUser) {
      throw new Error('User not logged in')
    }

    const authorization = await this.context.dispatch('getAuthorizationHeader')
    const { userGoals } = await UserGoalsService.userGoalsList(authorization)
    this.context.commit('setUserGoals', userGoals)

    const goalsCompleted = userGoals.filter(o => o.status === 'complete').map(o => o.name)
    userAnalyticsProfile.set({
      goalsCompleted,
      goalsCompletedCount: goalsCompleted.length
    })

    return userGoals
  }

  @Action({ rawError: true })
  async userGoalProgress ({ userGoalId, props }: { userGoalId: string, props?: { increment: number } | { update: number } | { data: string[] } }) {
    if (!this.firebaseUser) {
      throw new Error('User not logged in')
    }

    const authorization = await this.context.dispatch('getAuthorizationHeader')
    const { userGoal } = await UserGoalsService.userGoalProgress(authorization, userGoalId, props || { increment: 1 })
    this.context.commit('upsertUserGoal', userGoal)
    return userGoal
  }

  @Action({ rawError: true })
  async userNotificationsUpdate ({ authorization, update }: { authorization: string, update: UserNotificationsPartial }) {
    const notifications = await UserNotificationsService.userNotificationsUpdate(authorization, update)
    return notifications
  }

  @Action({ rawError: true })
  async newsSubscribe ({ email, name }: { email: string, name?: string }) {
    await NewsService.newsSubscribe({
      email,
      name
    })
  }
}
