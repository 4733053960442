import * as analytics from '@icepanel/app-analytics'
import { PermissionType, UserGoalName, UserNotifications } from '@icepanel/platform-api-client'

export const userPermission: analytics.Property<PermissionType> = {
  displayName: 'User permission',
  examples: ['admin', 'write', 'read'],
  name: 'user_permission',
  required: true,
  type: 'string'
}
export const userEmail: analytics.Property<string> = {
  displayName: 'User email',
  name: 'user_email',
  required: true,
  type: 'string'
}
export const userExists: analytics.Property<boolean> = {
  displayName: 'User exists',
  name: 'user_exists',
  required: true,
  type: 'boolean'
}
export const userAuthProvider: analytics.Property<string> = {
  displayName: 'User auth provider',
  name: 'user_auth_provider',
  required: true,
  type: 'string'
}
export const userJobRole: analytics.Property<string | undefined> = {
  displayName: 'User job role',
  name: 'user_job_role',
  required: false,
  type: 'string'
}
export const userGoalName: analytics.Property<UserGoalName> = {
  displayName: 'User goal name',
  examples: ['model-objects', 'create-comment', 'open-context-diagram', 'zoom-into-object'],
  name: 'user_goal_name',
  required: true,
  type: 'string'
}
export const userNotifications: analytics.Property<UserNotifications> = {
  displayName: 'User notifications',
  name: 'user_notifications',
  required: true,
  type: 'object'
}
export const userGoalProgressCount: analytics.Property<number> = {
  displayName: 'User goal progress count',
  name: 'user_goal_progress_count',
  required: true,
  type: 'number'
}
export const userGoalProgressData: analytics.Property<string[]> = {
  displayName: 'User goal progress data',
  name: 'user_goal_progress_data',
  required: true,
  type: 'array'
}
export const userGoalStepCount: analytics.Property<number> = {
  displayName: 'User goal step count',
  name: 'user_goal_step_count',
  required: true,
  type: 'number'
}
export const userReferredBy: analytics.Property<string | undefined> = {
  displayName: 'User referred by',
  name: 'user_referred_by',
  required: false,
  type: 'string'
}
export const userReferredByUserId: analytics.Property<string | undefined> = {
  displayName: 'User referred by user ID',
  name: 'user_referred_by_user_id',
  required: false,
  type: 'string'
}
export const userReferredByOrganizationId: analytics.Property<string | undefined> = {
  displayName: 'User referred by organization ID',
  name: 'user_referred_by_organization_id',
  required: false,
  type: 'string'
}
export const userC4ModelExperience: analytics.Property<string | undefined> = {
  displayName: 'User C4 model experience',
  name: 'user_c4_model_experience',
  required: false,
  type: 'string'
}

export const userUpdateEmail: analytics.Property<string | undefined> = {
  displayName: 'User update email',
  name: 'user_update_email',
  required: false,
  type: 'string'
}
export const userUpdateName: analytics.Property<string | undefined> = {
  displayName: 'User update name',
  name: 'user_update_name',
  required: false,
  type: 'string'
}
export const userUpdateJobRole: analytics.Property<string | undefined> = {
  displayName: 'User update job role',
  name: 'user_update_job_role',
  required: false,
  type: 'string'
}
export const userUpdateReferredBy: analytics.Property<string | undefined> = {
  displayName: 'User update referred by',
  name: 'user_update_referred_by',
  required: false,
  type: 'string'
}
export const userUpdateReferredByUserId: analytics.Property<string | undefined> = {
  displayName: 'User update referred by user ID',
  name: 'user_update_referred_by_user_id',
  required: false,
  type: 'string'
}
export const userUpdateReferredByOrganizationId: analytics.Property<string | undefined> = {
  displayName: 'User update referred by organization ID',
  name: 'user_update_referred_by_organization_id',
  required: false,
  type: 'string'
}
export const userUpdateNotifications: analytics.Property<UserNotifications | undefined> = {
  displayName: 'User update notifications',
  name: 'user_update_notifications',
  required: false,
  type: 'object'
}
export const userUpdateC4ModelExperience: analytics.Property<string | undefined> = {
  displayName: 'User update C4 model experience',
  name: 'user_update_c4_model_experience',
  required: false,
  type: 'string'
}

/**
 * Hidden
 */

export const userCompanySizeHidden: analytics.Property<string> = {
  displayName: 'User company size',
  examples: ['1-9', '10-49', '50-249', '250+'],
  hidden: true,
  name: 'user_company_size',
  required: true,
  type: 'string'
}
export const userSendNewsHidden: analytics.Property<boolean> = {
  displayName: 'User send news',
  hidden: true,
  name: 'user_send_news',
  required: true,
  type: 'boolean'
}
