import * as analytics from '@icepanel/app-analytics'
import { TagColor } from '@icepanel/platform-api-client'

export const teamId: analytics.Property<string[]> = {
  displayName: 'Team ID',
  name: 'team_id',
  required: true,
  type: 'array'
}
const organizationId: analytics.Property<string[]> = {
  displayName: 'Organization ID',
  name: 'organization_id',
  required: true,
  type: 'array'
}
const userId: analytics.Property<string[]> = {
  displayName: 'User ID',
  name: 'user_id',
  required: true,
  type: 'array'
}
const landscapeId: analytics.Property<string[]> = {
  displayName: 'Landscape ID',
  name: 'landscape_id',
  required: true,
  type: 'array'
}

const createdAt: analytics.Property<Date> = {
  displayName: 'Created at',
  name: '$created',
  required: true,
  type: 'date'
}
const name: analytics.Property<string> = {
  displayName: 'Name',
  name: '$name',
  required: true,
  type: 'string'
}
const modelObjectId: analytics.Property<string[]> = {
  displayName: 'Model object ID',
  name: 'model_object_id',
  required: true,
  type: 'array'
}
const modelObjectCount: analytics.Property<number> = {
  displayName: 'Model object count',
  name: 'model_object_count',
  required: true,
  type: 'number'
}
const landscapeCount: analytics.Property<number> = {
  displayName: 'Landscape count',
  name: 'landscape_count',
  required: true,
  type: 'number'
}
const userCount: analytics.Property<number> = {
  displayName: 'User count',
  name: 'user_count',
  required: true,
  type: 'number'
}
const color: analytics.Property<TagColor> = {
  displayName: 'Team color',
  name: 'team_color',
  required: true,
  type: 'string'
}

export default new analytics.Group('team_id', {
  color,
  createdAt,
  landscapeCount,
  landscapeId,
  modelObjectCount,
  modelObjectId,
  name,
  organizationId,
  userCount,
  userId
})
