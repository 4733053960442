import { getAuth, signOut, Unsubscribe } from 'firebase/auth'
import * as Vuex from 'vuex'
import { getModule } from 'vuex-module-decorators'

import * as firebase from '@/plugins/firebase'

import { UserModule } from '../store'

let unsubscribe: Unsubscribe | undefined
let store: Vuex.Store<any> | undefined

export const initialize = () => {
  unsubscribe?.()
  unsubscribe = getAuth(firebase.app).onAuthStateChanged(async user => {
    const userModule = getModule(UserModule, store)

    try {
      if (user && user.emailVerified) {
        userModule.setFirebaseUser(user)

        try {
          await userModule.userFind()
        } catch (err: any) {
          if (err.status === 404) {
            userModule.setUser(null)
          } else {
            throw err
          }
        }

        userModule.setLoaded(true)
      } else {
        userModule.setFirebaseUser(null)
        userModule.setUser(null)
        userModule.setLoaded(true)
      }
    } catch (err: any) {
      signOut(getAuth(firebase.app))

      userModule.setFirebaseUser(null)
      userModule.setUser(null)
      userModule.setLoaded(true)
    }
  })
}

export default async (_store: Vuex.Store<any>) => {
  if (window.Cypress) {
    const userModule = getModule(UserModule, store)
    userModule.setFirebaseUser(null)
    userModule.setUser(null)
    userModule.setLoaded(true)
  } else {
    store = _store
    await firebase.initialLoad
    initialize()
  }
}
