
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import WhatsNewDialog from '@/components/whats-new-dialog.vue'
import * as appAnalytics from '@/helpers/analytics'
import * as env from '@/helpers/env'
import getColor from '@/helpers/get-color'
import DiagramThumbnailMenu from '@/modules/diagram/components/thumbnail-menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUserInviteDialog from '@/modules/organization/components/user-invite-dialog.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import getInitials from '../helpers/get-initials'
import { UserModule } from '../store'
import JobRoleEdit from './job-role-edit.vue'
import NameEdit from './name-edit.vue'
import ProfileMenu from './profile-menu.vue'

const MINUTE_SECONDS = 60
const DAY_SECONDS = 60 * 60 * 24

interface IUser {
  color: string
  diagramId?: string
  id: string
  initials: string
  lastActive: Date
  link?: RawLocation
  linkName?: string
  name: string
}

@Component({
  components: {
    DiagramThumbnailMenu,
    JobRoleEdit,
    Menu,
    NameEdit,
    OrganizationUserInviteDialog,
    ProfileMenu,
    WhatsNewDialog
  },
  name: 'UserMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly menuOpts?: any

  editingName = false
  editingJobRole = false

  get whatsNewDialog () {
    return this.$queryValue('whats_new_dialog')
  }

  get userInviteDialog () {
    return this.$queryValue('user_invite_dialog')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get user () {
    return this.userModule.user
  }

  get userColor () {
    return this.user ? TAG_COLOR_ACTIVE[getColor(this.user.id[0])] : undefined
  }

  get userInitials () {
    return this.user?.name ? getInitials(this.user.name) : undefined
  }

  get currentlyActiveUsers () {
    const users: IUser[] = []

    const timestamp = Math.round(Date.now() / 1000)
    Object
      .values(this.editorModule.locations)
      .filter(o => o.userId !== this.userModule.user?.id && o.versionId && o.publishedAt >= timestamp - MINUTE_SECONDS)
      .forEach(o => {
        const user = this.currentLandscape ? this.organizationModule.organizationUsers[this.currentLandscape.organizationId]?.[o.userId] : undefined
        const diagram = o.diagramId ? this.diagramModule.diagrams[o.diagramId] : undefined
        const diagramModel = diagram ? this.modelModule.objects[diagram.modelId] : undefined
        const version = o.versionId ? this.versionModule.versions.find(v => v.id === o.versionId) : undefined

        let diagramId: string | undefined

        let link: RawLocation | undefined
        let linkName: string | undefined

        if (o.versionId === 'latest') {
          if (o.diagramId && diagram && diagramModel) {
            diagramId = diagram.id
            linkName = `In ${diagram.name}`

            if (this.currentDiagram?.id !== diagram.id) {
              link = {
                name: 'editor-diagram',
                params: {
                  landscapeId: this.currentLandscapeId
                },
                query: this.$setQuery({
                  comment: undefined,
                  connection: undefined,
                  diagram: diagram.handleId,
                  flow: undefined,
                  flow_parent: undefined,
                  flow_path: undefined,
                  flow_step: undefined,
                  model: diagramModel.handleId,
                  object: undefined,
                  users_menu: undefined,
                  x1: undefined,
                  x2: undefined,
                  y1: undefined,
                  y2: undefined
                })
              }
            }
          } else {
            linkName = 'Overview'
            link = {
              name: 'diagrams',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: 'latest'
              }
            }
          }
        } else if (o.versionId && version) {
          if (diagram && diagramModel) {
            linkName = `In ${diagram.name}`

            if (this.currentDiagram?.id !== diagram.id || this.currentVersionId !== version.id) {
              link = {
                name: 'version-diagram',
                params: {
                  landscapeId: this.currentLandscapeId,
                  versionId: version.id
                },
                query: this.$setQuery({
                  comment: undefined,
                  connection: undefined,
                  diagram: diagram.handleId,
                  flow: undefined,
                  flow_parent: undefined,
                  flow_path: undefined,
                  flow_step: undefined,
                  model: diagramModel.handleId,
                  object: undefined,
                  users_menu: undefined,
                  x1: undefined,
                  x2: undefined,
                  y1: undefined,
                  y2: undefined
                })
              }
            }
          } else if (o.diagramId) {
            linkName = version.tags.includes('latest') ? 'Latest' : `Version ${version.name}`
            link = {
              name: 'version-diagram',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: version.id
              },
              query: this.$setQuery({
                comment: undefined,
                connection: undefined,
                diagram: o.diagramId,
                flow: undefined,
                flow_parent: undefined,
                flow_path: undefined,
                flow_step: undefined,
                model: undefined,
                object: undefined,
                users_menu: undefined,
                x1: undefined,
                x2: undefined,
                y1: undefined,
                y2: undefined
              })
            }
          } else {
            linkName = version.tags.includes('latest') ? 'Latest' : `Version ${version.name}`
            link = {
              name: 'diagrams',
              params: {
                landscapeId: this.currentLandscapeId,
                versionId: version.id
              }
            }
          }
        }

        if (user) {
          const color = getColor(o.userId)
          users.push({
            color: TAG_COLOR_ACTIVE[color],
            diagramId,
            id: o.userId,
            initials: user?.name ? getInitials(user.name) : '',
            lastActive: new Date(o.publishedAt * 1000),
            link,
            linkName,
            name: user.name || ''
          })
        }
      })

    return users
  }

  get recentlyActiveUsers () {
    const users: IUser[] = []

    const timestamp = Math.round(Date.now() / 1000)
    Object
      .values(this.editorModule.locations)
      .filter(o => o.userId !== this.userModule.user?.id && (!o.versionId || (o.publishedAt > timestamp - DAY_SECONDS && o.publishedAt < timestamp - MINUTE_SECONDS)))
      .forEach(o => {
        const user = this.currentLandscape ? this.organizationModule.organizationUsers[this.currentLandscape.organizationId]?.[o.userId] : undefined
        if (user) {
          const color = getColor(o.userId)
          users.push({
            color: TAG_COLOR_ACTIVE[color],
            id: o.userId,
            initials: user?.name ? getInitials(user.name) : '',
            lastActive: new Date(o.publishedAt * 1000),
            name: user.name || ''
          })
        }
      })

    return users
  }

  open () {
    analytics.userMenu.track(this)
  }

  close () {
    this.editingName = false
  }

  feedbackLink () {
    const contactPath = `contact?requestType=support&name=${this.userModule.user?.name}&email=${this.userModule.user?.email}`
    const url = env.IS_PRODUCTION ? `https://icepanel.io/${contactPath}` : `https://icepanel.dev/${contactPath}`

    window.open(url, '_blank')?.focus()

    appAnalytics.feedbackLink.track(this, {
      landscapeId: this.currentLandscape ? [this.currentLandscape.id] : undefined,
      organizationId: this.currentLandscape ? [this.currentLandscape.organizationId] : undefined
    })
  }
}
