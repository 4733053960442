import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretUp from '@fortawesome/free-solid-svg-icons/faCaretUp'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCheckSquare from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import * as fasCog from '@fortawesome/free-solid-svg-icons/faCog'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasEnvelope from '@fortawesome/free-solid-svg-icons/faEnvelope'
import * as fasInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as fasMapMarkedAlt from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasSearch from '@fortawesome/free-solid-svg-icons/faSearch'
import * as fasSquare from '@fortawesome/free-solid-svg-icons/faSquare'
import * as fasTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as fasTrashAlt from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import * as fasUserCog from '@fortawesome/free-solid-svg-icons/faUserCog'
import * as fasUserEdit from '@fortawesome/free-solid-svg-icons/faUserEdit'
import * as fasUserPlus from '@fortawesome/free-solid-svg-icons/faUserPlus'
import * as fasUsers from '@fortawesome/free-solid-svg-icons/faUsers'

export default [
  fasCaretDown.definition,
  fasCaretUp.definition,
  fasCheck.definition,
  fasCheckSquare.definition,
  fasCog.definition,
  fasEllipsisH.definition,
  fasEnvelope.definition,
  fasInfoCircle.definition,
  fasMapMarkedAlt.definition,
  fasPencilAlt.definition,
  fasPlus.definition,
  fasSearch.definition,
  fasSquare.definition,
  fasTimes.definition,
  fasTrashAlt.definition,
  fasUserCog.definition,
  fasUserEdit.definition,
  fasUserPlus.definition,
  fasUsers.definition
] as const
