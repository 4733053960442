import { Landscape, ModelObject, Team, TeamPartial, TeamRequired, TeamsService } from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'
import userAnalyticsProfile from '@/modules/user/helpers/analytics-profile'

import teamAnalyticsGroup from './helpers/analytics-group'

export interface ITeamModule {
  teams: Team[]
  teamLandscapes: Landscape[]
  teamLandscapeObjects: Record<string, ModelObject[]>

  teamFindStatus: Status
  teamsListStatus: Status<{ organizationId: string }, { organizationId: string }>
  teamCreateStatus: Status
  teamUpdateStatus: Status<{ update: TeamPartial }>
  teamDeleteStatus: Status
  teamLandscapesListStatus: Status
  teamObjectsListStatus: Status
}

const name = 'team'

@Module({
  name,
  namespaced: true
})
export class TeamModule extends VuexModule {
  static namespace = name

  teams: Team[] = []
  teamLandscapes: Landscape[] = []
  teamLandscapeObjects: Record<string, ModelObject[]> = {}

  teamFindStatus = new Status()
  teamsListStatus = new Status<{ organizationId: string }, { organizationId: string }>()
  teamCreateStatus = new Status()
  teamUpdateStatus = new Status<{ update: TeamPartial }>()
  teamDeleteStatus = new Status()
  teamLandscapesListStatus = new Status()
  teamObjectsListStatus = new Status()

  get userTeams () {
    const userId = this.context.rootState.user.user?.id
    return this.teams.filter(o => o.userIds.includes(userId))
  }

  @Mutation
  setTeam ({ team }: { team: Team }) {
    this.teams = [
      ...this.teams.filter(o => o.id !== team.id),
      team
    ]
    this.teams.sort((a, b) => a.name.localeCompare(b.name))
  }

  @Mutation
  setTeams ({ teams }: { teams: Team[] }) {
    this.teams = teams
    this.teams.sort((a, b) => a.name.localeCompare(b.name))
  }

  @Mutation
  resetTeams () {
    this.teams = []
    this.teamsListStatus.set(Status.idle())
  }

  @Mutation
  setTeamModelObjects ({ modelObjectHandleId, teamIds }: { modelObjectHandleId: string, teamIds?: string[] }) {
    this.teams.forEach(o => {
      if (teamIds?.includes(o.id)) {
        o.modelObjectHandleIds = [...new Set([
          ...o.modelObjectHandleIds,
          modelObjectHandleId
        ])]
      } else {
        o.modelObjectHandleIds = o.modelObjectHandleIds.filter(o => o !== modelObjectHandleId)
      }
    })
  }

  @Mutation
  setTeamLandscapes (landscapes: Landscape[]) {
    this.teamLandscapes = landscapes
    this.teamLandscapes.sort((a, b) => a.name.localeCompare(b.name))
  }

  @Mutation
  setTeamLandscapeObjects (landscapeObjects: Record<string, ModelObject[]>) {
    this.teamLandscapeObjects = landscapeObjects
  }

  @Mutation
  removeTeam (teamId: string) {
    this.teams = this.teams.filter(o => o.id !== teamId)
  }

  @Mutation
  setTeamFindStatus (...params: Parameters<typeof this.teamFindStatus.set>) {
    this.teamFindStatus.set(...params)
  }

  @Mutation
  setTeamListStatus (...params: Parameters<typeof this.teamsListStatus.set>) {
    this.teamsListStatus.set(...params)
  }

  @Mutation
  setTeamCreateStatus (...params: Parameters<typeof this.teamCreateStatus.set>) {
    this.teamCreateStatus.set(...params)
  }

  @Mutation
  setTeamUpdateStatus (...params: Parameters<typeof this.teamUpdateStatus.set>) {
    this.teamUpdateStatus.set(...params)
  }

  @Mutation
  setTeamDeleteStatus (...params: Parameters<typeof this.teamDeleteStatus.set>) {
    this.teamDeleteStatus.set(...params)
  }

  @Mutation
  setTeamLandscapesList (...params: Parameters<typeof this.teamLandscapesListStatus.set>) {
    this.teamLandscapesListStatus.set(...params)
  }

  @Mutation
  setTeamObjectsList (...params: Parameters<typeof this.teamObjectsListStatus.set>) {
    this.teamObjectsListStatus.set(...params)
  }

  @Action({ rawError: true })
  async teamFind ({ organizationId, teamId }: { organizationId: string, teamId: string }) {
    try {
      this.context.commit('setTeamFindStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { team } = await TeamsService.teamFind(authorization, organizationId, teamId)
      this.context.commit('setTeam', {
        team,
        userId: this.context.rootState.user.user?.id
      })

      teamAnalyticsGroup.set(team.id, {
        color: team.color,
        createdAt: team.createdAt,
        modelObjectCount: team.modelObjectHandleIds.length,
        modelObjectId: team.modelObjectHandleIds,
        name: team.name,
        organizationId: [team.organizationId],
        userCount: team.userIds.length,
        userId: team.userIds
      })

      this.context.commit('setTeamFindStatus', Status.success())

      return team
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamFindStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async teamsList (organizationId: string) {
    try {
      this.context.commit('setTeamListStatus', Status.loading({
        organizationId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { teams } = await TeamsService.teamsList(authorization, organizationId)
      this.context.commit('setTeams', {
        teams,
        userId: this.context.rootState.user.user?.id
      })

      teams.forEach(o => {
        teamAnalyticsGroup.set(o.id, {
          color: o.color,
          createdAt: o.createdAt,
          modelObjectCount: o.modelObjectHandleIds.length,
          modelObjectId: o.modelObjectHandleIds,
          name: o.name,
          organizationId: [o.organizationId],
          userCount: o.userIds.length,
          userId: o.userIds
        })
      })

      userAnalyticsProfile.set({
        teamCount: this.userTeams.length,
        teamId: this.userTeams.map(o => o.id)
      })

      this.context.commit('setTeamListStatus', Status.success({
        organizationId
      }))

      return teams
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async teamCreate ({ organizationId, create }: { organizationId: string, create: TeamRequired }) {
    try {
      this.context.commit('setTeamCreateStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { team } = await TeamsService.teamCreate(authorization, organizationId, create)
      this.context.commit('setTeam', {
        team,
        userId: this.context.rootState.user.user?.id
      })

      teamAnalyticsGroup.set(team.id, {
        color: team.color,
        createdAt: team.createdAt,
        modelObjectCount: team.modelObjectHandleIds.length,
        modelObjectId: team.modelObjectHandleIds,
        name: team.name,
        organizationId: [team.organizationId],
        userCount: team.userIds.length,
        userId: team.userIds
      })

      if (this.userTeams.some(o => o.id === team.id)) {
        userAnalyticsProfile.increment('teamCount', 1)
        userAnalyticsProfile.union('teamId', [team.id])
      }

      this.context.commit('setTeamCreateStatus', Status.success())

      return team
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamCreateStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  get generateTeamCommit () {
    return (id: string, props: Omit<TeamPartial, 'commit'>): { team: Team, teamUpdate: TeamPartial } => {
      const team = this.teams.find(o => o.id === id)
      if (team) {
        team.updatedAt = new Date().toISOString()
        team.updatedBy = 'user'
        team.updatedById = this.context.rootState.user.user?.id || ''
        Object.assign(team, props)
        return {
          team: {
            ...team,
            ...props
          },
          teamUpdate: {
            ...props
          }
        }
      } else {
        throw new Error(`Could not find team ${id}`)
      }
    }
  }

  @Action({ rawError: true })
  async teamUpdate ({ organizationId, teamId, update }: { organizationId: string, teamId: string, update: TeamPartial }) {
    try {
      this.context.commit('setTeamUpdateStatus', Status.loading({
        update
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { team } = await TeamsService.teamUpdate(authorization, organizationId, teamId, update)
      this.context.commit('setTeam', {
        team,
        userId: this.context.rootState.user.user?.id
      })

      teamAnalyticsGroup.set(team.id, {
        color: team.color,
        createdAt: team.createdAt,
        modelObjectCount: team.modelObjectHandleIds.length,
        modelObjectId: team.modelObjectHandleIds,
        name: team.name,
        organizationId: [team.organizationId],
        userCount: team.userIds.length,
        userId: team.userIds
      })

      this.context.commit('setTeamUpdateStatus', Status.success())

      return team
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamUpdateStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async teamDelete ({ organizationId, teamId }: { organizationId: string, teamId: string }) {
    try {
      this.context.commit('setTeamDeleteStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { team } = await TeamsService.teamDelete(authorization, organizationId, teamId)

      teamAnalyticsGroup.delete(teamId)

      if (this.userTeams.some(o => o.id === teamId)) {
        userAnalyticsProfile.increment('teamCount', -1)
        userAnalyticsProfile.remove('teamId', teamId)
      }

      this.context.commit('removeTeam', teamId)

      this.context.commit('setTeamDeleteStatus', Status.success())

      return team
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamDeleteStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async teamLandscapesList ({ organizationId, teamId }: { organizationId: string, teamId: string }) {
    try {
      this.context.commit('setTeamLandscapesList', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { landscapes } = await TeamsService.teamLandscapesList(authorization, organizationId, teamId)
      this.context.commit('setTeamLandscapes', landscapes)

      teamAnalyticsGroup.set(teamId, {
        landscapeCount: landscapes.length,
        landscapeId: landscapes.map(o => o.id)
      })

      this.context.commit('setTeamLandscapesList', Status.success())

      return landscapes
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamLandscapesList', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async teamObjectsList ({ organizationId, teamId }: { organizationId: string, teamId: string }) {
    try {
      this.context.commit('setTeamObjectsList', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { landscapeModelObjects } = await TeamsService.teamModelObjectsList(authorization, organizationId, teamId)
      this.context.commit('setTeamLandscapeObjects', landscapeModelObjects)

      const modelObjects = Object.values(landscapeModelObjects).flat()

      teamAnalyticsGroup.set(teamId, {
        modelObjectCount: modelObjects.length,
        modelObjectId: modelObjects.map(o => o.id)
      })

      this.context.commit('setTeamObjectsList', Status.success())

      return landscapeModelObjects
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setTeamObjectsList', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }
}
