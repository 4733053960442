import * as fabGithub from '@fortawesome/free-brands-svg-icons/faGithub'
import * as fabMarkdown from '@fortawesome/free-brands-svg-icons/faMarkdown'
import * as farSnowflake from '@fortawesome/free-regular-svg-icons/faSnowflake'
import * as fasArrowLeft from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import * as fasBook from '@fortawesome/free-solid-svg-icons/faBook'
import * as fasBug from '@fortawesome/free-solid-svg-icons/faBug'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCheckSquare from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import * as fasChevronCircleDown from '@fortawesome/free-solid-svg-icons/faChevronCircleDown'
import * as fasCommentDots from '@fortawesome/free-solid-svg-icons/faCommentDots'
import * as fasEye from '@fortawesome/free-solid-svg-icons/faEye'
import * as fasEyeSlash from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import * as fasGift from '@fortawesome/free-solid-svg-icons/faGift'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasSignOutAlt from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import * as fasSitemap from '@fortawesome/free-solid-svg-icons/faSitemap'
import * as fasSpinner from '@fortawesome/free-solid-svg-icons/faSpinner'
import * as fasUser from '@fortawesome/free-solid-svg-icons/faUser'
import * as fasUserCheck from '@fortawesome/free-solid-svg-icons/faUserCheck'
import * as fasUserCog from '@fortawesome/free-solid-svg-icons/faUserCog'
import * as fasUserLock from '@fortawesome/free-solid-svg-icons/faUserLock'

export default [
  fabGithub.definition,
  fabMarkdown.definition,
  farSnowflake.definition,
  fasArrowLeft.definition,
  fasBook.definition,
  fasBug.definition,
  fasCheck.definition,
  fasCheckSquare.definition,
  fasChevronCircleDown.definition,
  fasCommentDots.definition,
  fasEye.definition,
  fasEyeSlash.definition,
  fasGift.definition,
  fasPencilAlt.definition,
  fasSignOutAlt.definition,
  fasSitemap.definition,
  fasSpinner.definition,
  fasUser.definition,
  fasUserCheck.definition,
  fasUserCog.definition,
  fasUserLock.definition
] as const
